<template>
  <div class="icon-picker">
    <button
      aria-label="Icon picker open"
      @click="showPicker = !showPicker">
      <v-icon
        :class="['icon-picker__selected-icon', `${colorName}-color`]"
        :color="iconColor"
        size="24">
        {{ icon }}
      </v-icon>
    </button>
    <transition name="slide-y-transition">
      <v-card
        v-if="showPicker"
        v-click-outside="{
          handler: closePicker,
          include: getClickOutsideIncluded,
        }"
        class="mt-2 pa-0"
        max-width="250">
        <v-card-text
          data-cy="picker-card"
          class="icon-picker__icons-list">
          <button
            v-for="(iconName, index) in $static.icons"
            :key="index"
            class="icon-picker__icon"
            @click="$emit('update:icon', iconName)">
            <v-icon color="primary">
              {{ iconName }}
            </v-icon>
          </button>
        </v-card-text>

        <v-divider class="mb-2"/>

        <v-card-text class="px-2 py-0">
          <v-color-picker
            v-model="selectedColor"
            data-cy="color-picker"
            hide-canvas
            hide-inputs
            hide-mode-switch
            hide-sliders
            show-swatches
            :swatches="swatches"
            @input="onColorSelect"/>
        </v-card-text>
      </v-card>
    </transition>
  </div>
</template>

<script>
export const ICONS = [
  'mdi-cube-outline',
  'public',
  'extension',
  'science',
  'bug_report',
  'bolt',
  'call_merge',
  'commit',
  'mdi-source-branch',
  'traffic',
  'mdi-clipboard-check-outline',
  'mdi-progress-clock',
  'visibility',
  'vpn_key',
  'lightbulb',
  'favorite',
  'star',
  'auto_awesome',
  'mdi-controller-classic',
  'precision_manufacturing',
  'tour',
  'podcasts',
  'inventory',
  'save',
  'security',
  'mdi-lifebuoy',
  'mdi-ab-testing',
  'mdi-api',
  'mdi-console',
  'mdi-database',
  'mdi-vpn',
  'mdi-server',
  'mdi-server-security',
  'mdi-network-outline',
  'mdi-lan',
  'mdi-nas',
  'mdi-ansible',
  'mdi-aws',
  'mdi-microsoft-azure',
  'mdi-google-cloud',
  'mdi-kubernetes',
  'mdi-terraform',
]

export const ENV_PRESETS = [
  {
    name: 'dev',
    background: '#C6D2E3',
    foreground: '#3B4F6B',
    icon: 'commit',
  },
  {
    name: 'prod',
    background: '#A4D5D5',
    foreground: '#115B5B',
    icon: 'public',
  },
  {
    name: 'demo',
    background: '#A4D5D5',
    foreground: '#115B5B',
    icon: 'auto_awesome',
  },
  {
    name: 'success',
    background: '#97CF8F',
    foreground: '#316929',
    icon: 'lightbulb',
  },
  {
    name: 'default',
    background: '#BBA6A8',
    foreground: '#582026',
    icon: 'extension',
  },
  {
    name: 'error',
    background: '#F07A8A',
    foreground: '#5C0D18',
    icon: 'bug_report',
  },
  {
    name: 'staging',
    background: '#F6B967',
    foreground: '#905301',
    icon: 'science',
  },
  {
    name: 'preprod',
    background: '#F6B967',
    foreground: '#905301',
    icon: 'traffic',
  },
]

export const DEFAULT_COLOR_NAME = 'default'
export const DEFAULT_COLOR = '#BBA6A8'
export const DEFAULT_ICON = 'extension'
export const DEFAULT_ICON_COLOR = '#582026'

export default {
  name: 'CyInputsIconPicker',
  props: {
    colorName: {
      type: String,
      default: DEFAULT_COLOR_NAME,
    },
    icon: {
      type: String,
      default: DEFAULT_ICON,
    },
    envCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showPicker: false,
    selectedColor: DEFAULT_COLOR,
  }),
  computed: {
    $static: () => ({
      icons: ICONS,
    }),
    swatches () {
      return _.uniqBy(ENV_PRESETS, 'background').map((color) => [color.background])
    },
    colorItem () {
      const color = _.find(ENV_PRESETS, { name: this.colorName })
      return color
    },
    iconColor () {
      return this.colorItem?.foreground || DEFAULT_ICON_COLOR
    },
  },
  watch: {
    envCanonical (value) {
      const envPreset = _.find(ENV_PRESETS, { name: value })
      if (envPreset) {
        this.$emit('update:icon', envPreset.icon)
        this.$set(this, 'selectedColor', envPreset.background)
        this.onColorSelect()
      }
    },
  },
  created () {
    if (!ICONS.includes(this.icon)) {
      this.$emit('update:icon', DEFAULT_ICON)
    }

    if (!this.colorItem) {
      this.$emit('update:colorName', DEFAULT_COLOR_NAME)
    } else {
      this.selectedColor = this.colorItem.background
    }
  },
  methods: {
    closePicker () {
      this.showPicker = false
    },
    onColorSelect () {
      const newColor = _.find(ENV_PRESETS, { background: this.selectedColor })
      this.$emit('update:colorName', newColor.name || DEFAULT_COLOR_NAME)
    },
    getClickOutsideIncluded () {
      return [document.querySelector('.icon-picker__selected-icon')]
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-picker {
  &__selected-icon {
    position: relative;
    z-index: 0;
    border: 1px solid get-color("white");
    border-radius: 4px;
    padding: $spacer;

    &.dev-color {
      background-color: map.get($slate-grey, "light-4");
    }

    &.prod-color {
      background-color: map.get($teal, "light-2");
    }

    &.success-color {
      background-color: map.get($green, "light-1");
    }

    &.error-color {
      background-color: map.get($red, "light-1");
    }

    &.default-color {
      background-color: map.get($brown, "light-3");
    }

    &.staging-color {
      background-color: map.get($orange, "light-2");
    }

    &::after {
      content: none;
    }
  }

  .icon-picker__icons-list {
    display: flex;
    flex-wrap: wrap;
    padding: 2 * $spacer;
    gap: 2 * $spacer;
  }

  .icon-picker__icon {
    padding: $spacer;

    > i {
      width: 24px;
    }
  }
}

::v-deep v-icon {
  color: map.get($slate-grey, "light-1") !important;
}

::v-deep .v-color-picker {
  &__swatches {
    overflow-y: visible;

    & > div {
      flex-wrap: nowrap;
      padding: 0;
    }
  }

  &__swatch {
    margin-bottom: 6px;
  }

  &__color {
    width: 32px;
    height: 32px;
    max-height: 32px;
    border-radius: 4px;
  }
}

::v-deep .v-card {
  position: absolute;
  z-index: 1;
}
</style>
